var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-center mt-10"},[_c('span',{staticClass:"title font-weight-bold secondary--text mb-5"},[_vm._v("What would you like to do next?")]),_c('div',{staticClass:"d-flex justify-center"},[(_vm.applications.length)?_c('v-btn',{staticClass:"mr-3",attrs:{"to":{
                name: 'app-open',
                params: { oid: _vm.$route.params.oid, sid: _vm.$route.params.sid, iid: _vm.$route.params.iid, snid: _vm.$route.params.snid, aid: _vm.applications[0].aid }
            },"color":"primary","outlined":"","text":""}},[_vm._v(" Launch the Application ")]):_vm._e(),_c('v-btn',{staticClass:"mr-3",attrs:{"to":{
                name: 'snapshot-files',
                params: {
                    oid: _vm.$route.params.oid,
                    sid: _vm.$route.params.sid,
                    iid: _vm.$route.params.iid,
                    snid: _vm.$route.params.snid,
                    localPath: _vm.currentFileLocalPath,
                    fileArea: _vm.fileAreaType
                }
            },"color":"primary","outlined":"","text":""}},[_vm._v(" Add Files ")]),_c('v-btn',{staticClass:"mr-3",attrs:{"to":{
                name: 'space-user-management',
                params: { oid: _vm.$route.params.oid, sid: _vm.$route.params.sid, iid: _vm.$route.params.iid, snid: _vm.$route.params.snid }
            },"color":"primary","outlined":"","text":""}},[_vm._v(" Invite Users ")]),_c('v-btn',{attrs:{"to":{
                name: 'snapshot-overview',
                params: { oid: _vm.$route.params.oid, sid: _vm.$route.params.sid, iid: _vm.$route.params.iid, snid: _vm.$route.params.snid }
            },"color":"primary","outlined":"","text":""}},[_vm._v(" Go to Space Overview ")])],1),_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('v-btn',{staticClass:"caption font-weight-bold",attrs:{"to":{ name: 'home-dashboard', params: { oid: _vm.$route.params.oid } },"color":"primary","text":""}},[_vm._v(" Return to Dashboard ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }